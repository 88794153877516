import React, { createContext, useEffect, useReducer, useState } from 'react'
import { Web3Auth } from '@web3auth/modal'
import type { SafeEventEmitterProvider } from '@web3auth/base'
import { NEXT_PUBLIC_WEB3AUTH_CLIENT_ID } from '@/configs'
import { CHAIN_NAMESPACES } from '@web3auth/base'
import { CONTRACTS } from '@/configs/contracts'
import { AMPLITUDE_API, appVersion } from '@/configs'
import * as amplitude from '@amplitude/analytics-browser'
import { OpenloginAdapter } from '@web3auth/openlogin-adapter'
import { CoinbaseAdapter } from '@web3auth/coinbase-adapter'

// Plugins
import { TorusWalletConnectorPlugin } from '@web3auth/torus-wallet-connector-plugin'

// Adapters

import { WalletConnectV1Adapter } from '@web3auth/wallet-connect-v1-adapter'
import { MetamaskAdapter } from '@web3auth/metamask-adapter'
import { TorusWalletAdapter } from '@web3auth/torus-evm-adapter'

type providerType = SafeEventEmitterProvider | null

interface ContextProps {
  web3Auth: Web3Auth | undefined
  provider: providerType
  setProvider: (arg: providerType) => void
}

interface Props {
  children: JSX.Element
}

const initialContext = {} as ContextProps

const reducer = (state = initialContext, action: any) => {
  switch (action.type) {
    case 'SET_WEB_3_AUTH':
      return { ...state, web3Auth: action.payload }
    case 'SET_PROVIDER':
      return { ...state, provider: action.payload }
  }
}

export const Web3AuthContext = createContext(initialContext)

export const Web3AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialContext)
  const [lastNetwork, setLastNetwork] = useState()

  function setProvider(arg: providerType): void {
    dispatch({
      type: 'SET_PROVIDER',
      payload: arg,
    })
  }
  const configuration = {
    appVersion,
  }

  amplitude.init(AMPLITUDE_API, '', configuration)

  useEffect(() => {
    const key = 'Tender3SessionID'
    const myItem = window.localStorage.getItem(key)

    if (myItem === null) {
      amplitude.track('first_start')
    }
    window.localStorage.setItem(key, String(Date.now()))

    const lkey = 'LastNework'
    const myNetwork = window.localStorage.getItem(lkey)
    if (myNetwork !== null && myNetwork !== '0x1') {
      setLastNetwork(myNetwork)
    } else {
      setLastNetwork('0x5')
      window.localStorage.setItem(lkey, '0x5')
    }
  }, [])

  async function setWeb3Auth() {
    if (!NEXT_PUBLIC_WEB3AUTH_CLIENT_ID) throw 'useWeb3Auth: clientId not provided'
    const clientId = NEXT_PUBLIC_WEB3AUTH_CLIENT_ID
    const currentChain = lastNetwork
    try {
      const web3auth = new Web3Auth({
        clientId,
        web3AuthNetwork: 'mainnet', // mainnet, aqua, celeste, cyan or testnet
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: currentChain,
          rpcTarget: CONTRACTS[currentChain].rpcUrl, // This is the public RPC we have added, please pass on your own endpoint while creating an app
        },
        uiConfig: {
          theme: 'dark',
          loginMethodsOrder: ['google', 'twitter', 'facebook', 'apple'],
          defaultLanguage: 'en',
          appLogo: 'https://web3auth.io/images/w3a-L-Favicon-1.svg', // Your App Logo Here
        },
      })

      const openloginAdapter = new OpenloginAdapter({
        loginSettings: {
          mfaLevel: 'optional',
        },
        adapterSettings: {
          network: 'mainnet',
          whiteLabel: {
            name: 'Tender3',
            logoLight: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
            logoDark: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
            defaultLanguage: 'en',
            dark: true, // whether to enable dark mode. defaultValue: false
          },
        },
      })
      web3auth.configureAdapter(openloginAdapter)

      // plugins and adapters are optional and can be added as per your requirement
      // read more about plugins here: https://web3auth.io/docs/sdk/web/plugins/

      // adding torus wallet connector plugin

      // const torusPlugin = new TorusWalletConnectorPlugin({
      //   torusWalletOpts: {},
      //   walletInitOptions: {
      //     whiteLabel: {
      //       theme: { isDark: true, colors: { primary: '#00a8ff' } },
      //       logoDark: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
      //       logoLight: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
      //     },
      //     useWalletConnect: true,
      //     enableLogging: true,
      //   },
      // })
      // await web3auth.addPlugin(torusPlugin)

      //read more about adapters here: https://web3auth.io/docs/sdk/web/adapters/

      // adding wallet connect v1 adapter
      const walletConnectV1Adapter = new WalletConnectV1Adapter({
        adapterSettings: {
          bridge: 'https://bridge.walletconnect.org',
        },
        clientId,
      })

      web3auth.configureAdapter(walletConnectV1Adapter)

      // adding metamask adapter
      const metamaskAdapter = new MetamaskAdapter({
        clientId,
        sessionTime: 3600, // 1 hour in seconds
        web3AuthNetwork: 'mainnet',
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: currentChain,
          rpcTarget: CONTRACTS[currentChain].rpcUrl, // This is the public RPC we have added, please pass on your own endpoint while creating an app
        },
      })
      // we can change the above settings using this function
      metamaskAdapter.setAdapterSettings({
        sessionTime: 86400, // 1 day in seconds
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: currentChain,
          rpcTarget: CONTRACTS[currentChain].rpcUrl, // This is the public RPC we have added, please pass on your own endpoint while creating an app
        },
        web3AuthNetwork: 'mainnet',
      })

      const coinbaseAdapter = new CoinbaseAdapter({
        clientId,
        sessionTime: 3600, // 1 hour in seconds
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: currentChain,
          rpcTarget: CONTRACTS[currentChain].rpcUrl, // This is the public RPC we have added, please pass on your own endpoint while creating an app
        },
        web3AuthNetwork: 'mainnet',
      })
      web3auth.configureAdapter(coinbaseAdapter)

      // You can also change the adapter settings later on
      coinbaseAdapter.setAdapterSettings({
        sessionTime: 86400, // 1 day in seconds
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: currentChain,
          rpcTarget: CONTRACTS[currentChain].rpcUrl, // This is the public RPC we have added, please pass on your own endpoint while creating an app
        },
        web3AuthNetwork: 'mainnet',
      })

      // it will add/update  the metamask adapter in to web3auth class
      web3auth.configureAdapter(metamaskAdapter)

      const torusWalletAdapter = new TorusWalletAdapter({
        clientId,
      })

      // it will add/update  the torus-evm adapter in to web3auth class
      web3auth.configureAdapter(torusWalletAdapter)

      dispatch({
        type: 'SET_WEB_3_AUTH',
        payload: web3auth,
      })
      await web3auth.initModal()
    } catch (error: any) {
      throw error
    }
  }

  useEffect(() => {
    if (lastNetwork) {
      setWeb3Auth()
    }
  }, [lastNetwork])

  const values = { web3Auth: state?.web3Auth, provider: state?.provider, setProvider }

  return <Web3AuthContext.Provider value={values}>{children}</Web3AuthContext.Provider>
}

export default Web3AuthProvider
