import { useEffect, useState } from 'react'
import Notify from 'bnc-notify'
import { API_URL } from '@/configs'
import { IRootState } from '@/store'
import { useSelector } from 'react-redux'

const useNotify = () => {
  const { currentNetwork } = useSelector((state: IRootState) => state.wallet)
  const [networkId, setNetworkId] = useState(Number(currentNetwork))
  const [Network, setNetwork] = useState(false)
  function initNotify() {
    return Notify({
      dappId: API_URL, // [String] The API key created by step one above
      networkId, // [Integer] The Ethereum network ID your Dapp uses.
    })
  }

  useEffect(() => {
    setNetworkId(Number(currentNetwork))
    setNetwork(true)
  }, [currentNetwork])

  useEffect(() => {
    initNotify()
    setNetwork(false)
  }, [Network])
}

export default useNotify
