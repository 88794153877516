import {
  NEXT_PUBLIC_ALCHEMY_API_GOERLI,
  NEXT_PUBLIC_ALCHEMY_API_MAINNET,
  NEXT_PUBLIC_ALCHEMY_API_POLYGON,
  NEXT_PUBLIC_ALCHEMY_API_SEPOLIA,
} from '@/configs'

interface IChain {
  id: string
  number: number
  token: string
  name: string
  rpcUrl: string
  blockExplorers: string
  decimals: number
}

export interface WalletInterface {
  Provider: any
  ProviderEvents: any
  currentAccount: any
  currentNetwork: any
  wallet: any
  wrongNetwork: boolean
  Signer: any
  SignerEvents: any
  supportedNetworks: string[]
  balanceETH: any
  currency: string
  ContractAddress: string
  bigReset: boolean
  activateReset: boolean
  web3auth: any
  newProvider: any
  currentChain: string
  chains: IChain[]
  walletBalance: any
  familyAccounts: []
  lovBalance: any
}

export const WALLET_STATE: WalletInterface = {
  Provider: null,
  ProviderEvents: null,
  currentAccount: null,
  wallet: null,
  familyAccounts: [],
  currentNetwork: null,
  wrongNetwork: false,
  Signer: null,
  SignerEvents: null,
  supportedNetworks: ['0x5', '0xaa36a7'],
  balanceETH: null,
  lovBalance: null,
  currency: '',
  ContractAddress: '',
  bigReset: false,
  activateReset: false,
  //
  web3auth: null,
  newProvider: null,
  currentChain: '0x5',
  walletBalance: {},
  chains: [
    {
      id: '0x5',
      number: 5,
      token: 'gETH',
      name: 'Goerli',
      rpcUrl: `https://eth-goerli.g.alchemy.com/v2/${NEXT_PUBLIC_ALCHEMY_API_GOERLI}`,
      blockExplorers: `https://goerli.etherscan.io`,
      decimals: 18,
    },

    {
      id: '0xaa36a7',
      number: 11155111,
      token: 'sETH',
      name: 'Sepolia',
      rpcUrl: `https://eth-sepolia.g.alchemy.com/v2/${NEXT_PUBLIC_ALCHEMY_API_SEPOLIA}`,
      blockExplorers: `https://sepolia.etherscan.io`,
      decimals: 18,
    },
  ],
}
