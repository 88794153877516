export const CONTRACT_STATE = {
  familyStats: null,
  waveInstance: null,
  waveInstanceEvents: null,
  policyDays: null,
  marryDate: null,
  marryStatus: null,
  familyMembers: null,
  currentBalances: null,
  currentVotes: null,
  votesLength: 0,
  displayVotes: [],
  familyBalance: null,
  checkBalances: false,
  loadedNFTs: null,
  availablePair: null,
  familyMembersCount: 2,
  uniswapStatus: null,
  compoundStatus: false,
  demo: false,
  acceptedMembers: null,
  promoDays: null,
  divideShare: null,
  setDeadline: null,
  totalValue: null,
  bigReset: false,
  activateReset: true,
  value: 0,
  selectedVote: null,
  createDate: 0,
  minimumDeadline: null,
  proposer: '',
  proposed: '',
  threshold: 1,
  stake: 0,
  familyDao: null,
  connectedApps: null,
  errorMessage: '',
  userInfo: null,
}
