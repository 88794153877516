import { combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit'
import contractReducer from './features/contract/reducer'
import walletReducer from './features/wallet/reducer'
import mainContractReducer from './features/mainContract/reducer'
import { useDispatch } from 'react-redux'

const store = configureStore({
  reducer: {
    contract: contractReducer,
    mainContract: mainContractReducer,
    wallet: walletReducer,
  },
  middleware: new MiddlewareArray().concat(),
})

export const useAppDispatch: () => AppDispatch = useDispatch
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
const rootReducer = combineReducers({
  contract: contractReducer,
  mainContract: mainContractReducer,
  wallet: walletReducer,
})
export type IRootState = ReturnType<typeof rootReducer>

export default store
