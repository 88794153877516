import { createSlice } from '@reduxjs/toolkit'
import { WALLET_STATE, WalletInterface } from '@/store/features/wallet/state'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: WalletInterface = WALLET_STATE

export const WalletSlice = createSlice({
  name: 'Wallet',
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.Provider = action.payload
    },
    setProviderEvents: (state, action) => {
      state.ProviderEvents = action.payload
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload
    },
    setWallet: (state, action) => {
      state.wallet = action.payload
    },
    setCurrentNetwork: (state, action) => {
      state.currentNetwork = action.payload
    },
    setWrongNetwork: (state, action: PayloadAction<boolean>) => {
      state.wrongNetwork = action.payload
    },
    setSigner: (state, action) => {
      state.Signer = action.payload
    },
    setSignerEvents: (state, action) => {
      state.SignerEvents = action.payload
    },
    setBalanceETH: (state, action) => {
      state.balanceETH = action.payload
    },
    setBigReset: (state, action) => {
      state.bigReset = action.payload
    },
    setActivateReset: (state, action) => {
      state.activateReset = action.payload
    },
    setWeb3auth: (state, action) => {
      state.web3auth = action.payload
    },
    setNewProvider: (state, action) => {
      state.newProvider = action.payload
    },
    setChain: (state, action) => {
      state.currentChain = state.chains[action.payload].id
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload
    },
    setFamilyAccounts: (state, action) => {
      state.familyAccounts = action.payload
    },
    setLovBalance: (state, action) => {
      state.lovBalance = action.payload
    },
  },
})
// Action creators are generated for each case reducer function
export const {
  setProvider,
  setProviderEvents,
  setCurrentAccount,
  setWallet,
  setCurrentNetwork,
  setWrongNetwork,
  setSigner,
  setSignerEvents,
  setBalanceETH,
  setBigReset,
  setActivateReset,
  setWeb3auth,
  setNewProvider,
  setChain,
  setWalletBalance,
  setFamilyAccounts,
  setLovBalance,
} = WalletSlice.actions

export default WalletSlice.reducer
