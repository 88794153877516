import { createSlice } from '@reduxjs/toolkit'
import { CONTRACT_STATE } from '@/store/features/contract/state'
import { ContractInterface } from '@/store/features/contract/contractInterface'

export interface ContractState {
  value: number
}

const initialState: ContractInterface = CONTRACT_STATE

export const ContractSlice = createSlice({
  name: 'Contract',
  initialState,
  reducers: {
    setCurrentBalances: (state, action) => {
      state.currentBalances = action.payload
    },
    setCurrentVotes: (state, action) => {
      state.currentVotes = action.payload
    },
    setVotesLengthToStore: (state, action) => {
      state.votesLength = action.payload
    },
    setDisplayVotes: (state, action) => {
      state.displayVotes = action.payload
    },
    setFamilyBalance: (state, action) => {
      state.familyBalance = action.payload
    },
    setCheckBalances: (state, action) => {
      state.checkBalances = action.payload
    },
    setTotalValue: (state, action) => {
      state.totalValue = action.payload
    },
    setSelectedVote: (state, action) => {
      state.selectedVote = action.payload
    },
    setCreateDate: (state, action) => {
      state.createDate = action.payload
    },
    setFamilyStats: (state, action) => {
      state.familyStats = action.payload
    },
    setWaveInstance: (state, action) => {
      state.waveInstance = action.payload
    },
    setWaveInstanceEvents: (state, action) => {
      state.waveInstanceEvents = action.payload
    },
    setMarryDate: (state, action) => {
      state.marryDate = action.payload
    },
    setMarryStatus: (state, action) => {
      state.marryStatus = action.payload
    },
    setFamilyMembers: (state, action) => {
      state.familyMembers = action.payload
    },
    setFamilyMembersCount: (state, action) => {
      state.familyMembersCount = action.payload
    },
    setUniswapStatus: (state, action) => {
      state.uniswapStatus = action.payload
    },
    setCompoundStatus: (state, action) => {
      state.compoundStatus = action.payload
    },
    setDivideShare: (state, action) => {
      state.divideShare = action.payload
    },
    setMinimumDeadline: (state, action) => {
      state.minimumDeadline = action.payload
    },
    setPolicyDays: (state, action) => {
      state.policyDays = action.payload
    },
    setLoadedNFTs: (state, action) => {
      state.loadedNFTs = action.payload
    },
    setDemo: (state, action) => {
      state.demo = action.payload
    },
    setAcceptedMembers: (state, action) => {
      state.acceptedMembers = action.payload
    },
    contractReset: () => initialState,
    setProposer: (state, action) => {
      state.proposer = action.payload
    },
    setProposed: (state, action) => {
      state.proposed = action.payload
    },
    setThreshold: (state, action) => {
      state.threshold = action.payload
    },
    setStake: (state, action) => {
      state.stake = action.payload
    },
    setFamilyDao: (state, action) => {
      state.familyDao = action.payload
    },
    setConnectedApps: (state, action) => {
      state.connectedApps = action.payload
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },
    // maybe it should be created as another feature
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCurrentBalances,
  setCurrentVotes,
  setDisplayVotes,
  setFamilyBalance,
  setCheckBalances,
  setTotalValue,
  setSelectedVote,
  setCreateDate,
  setFamilyStats,
  setWaveInstance,
  setWaveInstanceEvents,
  setMarryDate,
  setMarryStatus,
  setFamilyMembers,
  setFamilyMembersCount,
  setUniswapStatus,
  setCompoundStatus,
  setDivideShare,
  setMinimumDeadline,
  setPolicyDays,
  setLoadedNFTs,
  setDemo,
  setAcceptedMembers,
  contractReset,
  setProposer,
  setProposed,
  setThreshold,
  setStake,
  setFamilyDao,
  setConnectedApps,
  setErrorMessage,
  setUserInfo,
  setVotesLengthToStore,
} = ContractSlice.actions

export default ContractSlice.reducer
