export const MAIN_CONTRACT_STATE = {
  currency: '',
  ContractAddress: '',
  marriageContract: null,
  marriageContractEvents: null,
  nftContractAddress: '',
  forwarderAddress: '',
  currentNetwork: '',
  currentNetworkName: '',
  nftPrice: null,
  etherscan: null,
  saleCap: null,
  exchangeRate: null,
  claimtimer: null,
  promoAmount: null,
  claimPolicyDays: null,
}

export interface MainContractInterface {
  currency: string
  ContractAddress: string
  marriageContract: any
  marriageContractEvents: any
  nftContractAddress: string
  forwarderAddress: string
  currentNetwork: string
  currentNetworkName: string
  nftPrice: any
  etherscan: any
  saleCap: any
  exchangeRate: any
  claimtimer: any
  promoAmount: any
  claimPolicyDays: any
}
