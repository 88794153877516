import { type ReactElement, useEffect, useState } from 'react'
import { ColorModeScript, ChakraProvider, extendTheme } from '@chakra-ui/react'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import { CacheProvider, type EmotionCache } from '@emotion/react'
import createEmotionCache from '@/utils/createEmotionCache'
import useNotify from '@/services/notify'
import Web3AuthProvider from '@/providers/web3auth'
import store from '@/store'
import { Provider } from 'react-redux'
import '@/scss/global.scss'

const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '160em', // 2560px
  '3xl': '320em', // 5120px
}

const InitApp = (): null => {
  useNotify()
  return null
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface WebCoreAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const CryptoMarry = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: WebCoreAppProps): ReactElement => {
  const theme = extendTheme({ breakpoints })
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      <Head>
        <title key="default-title">CryptoMarry</title>
      </Head>
      <ColorModeScript />
      <CacheProvider value={emotionCache}>
        <ChakraProvider theme={theme}>
          <Web3AuthProvider>
            <Provider store={store}>
              <InitApp />
              {isLoaded && <Component {...pageProps} />}
            </Provider>
          </Web3AuthProvider>
        </ChakraProvider>
      </CacheProvider>
    </>
  )
}

export default CryptoMarry
