import {
  NEXT_PUBLIC_ALCHEMY_API_GOERLI,
  NEXT_PUBLIC_ALCHEMY_API_MAINNET,
  NEXT_PUBLIC_ALCHEMY_API_POLYGON,
  NEXT_PUBLIC_ALCHEMY_API_SEPOLIA,
  ZERO_ADDR_ETH,
} from '@/configs'

interface IContract {
  ContractAddress: string
  currency: string
  nftContractAddress: string
  forwarderAddress: string
  nftResolutionAddress: string
  CompoundV3FacetUSDC: string
  CompoundV2Facet: string
  UniSwapV3Facet: string
  UniSwapV2Facet: string
  SushiSwapFacet: string
  QuickSwapV2Facet: string
  AaveV2Facet: string
  AaveV3Facet: string
  YearnFacet: string
  CurveFacet: string
  MakerFacet: string
  MakerInit2: string
  ParaSwapFacet: string
  OneInchV5Facet: string
  LidoFacet: string
  StargateFacet: string
  familyDao: string
  WethAddress: string
  usdcAddress: string
  cETHAddress: string
  cUSDCAddress: string
  linkAddress?: string
  compound: boolean
  ens: boolean
  Demo: {
    id: number
    stake: number
    proposer: string
    proposed: string
    ProposalStatus: number
    marriageContract: string
  }
  currentNetwork: string
  etherscan: string
  currentNetworkName: string
  openSea: string
  alchemy: string | undefined
  rpcUrl: string
}

interface IListContracts {
  [key: string]: IContract
}

export const CONTRACTS: IListContracts = {
  '0x5': {
    ContractAddress: '0x8E626ffb9c429828baCC3baA66fE04aBEb3da388',
    currency: 'gETH',
    nftContractAddress: '0x508A6414f149a30dC6dA6cAc56897da65a796F34',
    forwarderAddress: '0xf69600E1e6D853Eb5678Efe816198bE5A8813717',
    nftResolutionAddress: '0xF61594Fe5B6455BAB8AB704eA10F2d317C3D2e03',
    CompoundV3FacetUSDC: '0x5DF8077445df7d7F91214c27f102b6dfC51b8453',
    CompoundV2Facet: '0xb1fa4BDB1EdD6d8bbE75F15ef701111dB3A836eF',
    UniSwapV3Facet: '0x9c651ab915c9AA2F5FCB1E97C689cc0670031deF',
    UniSwapV2Facet: '0xD7c6be843855A2DBFe4dF502667Fe8c910e4184a',
    SushiSwapFacet: '0xa275b619E5B82e5Ae5f24Ff0e9b7370AB88810aA',
    QuickSwapV2Facet: '0x09c557fe09866c802Ee2e41bD3fC6d1a405Ac906',
    AaveV2Facet: '0xCa843f496763FF39bC367C61dB5b7B864f09a9c3',
    AaveV3Facet: '0x86A88A9936E5133B77323940d462a06Eb8dDdc9D',
    YearnFacet: '0x53fC9c05A624BB17e894b6E9A6b7d3223e40E5a8',
    CurveFacet: ZERO_ADDR_ETH,
    MakerFacet: '0x01137ba0E08f3F236daAD15A5f42f9dd944F757f',
    MakerInit2: '0xA606ce159020b1E81Fd374db17700DdC0b80Ab69',
    ParaSwapFacet: ZERO_ADDR_ETH,
    OneInchV5Facet: ZERO_ADDR_ETH,
    LidoFacet: '0x8388DDc9AFCe00c8dd6dd11d02C8f0a5c3fF72Fa',
    StargateFacet: '0xaF5D58e632886C6F706BEb88aee2EBa12139Bba6',
    familyDao: '0x41D9494dB42E5a26E149BF0B6550Dd520140c6eE',
    WethAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    usdcAddress: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    cETHAddress: '0x64078a6189Bf45f80091c6Ff2fCEe1B15Ac8dbde',
    cUSDCAddress: '0x73506770799Eb04befb5AaE4734e58C2C624F493',
    //'0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    compound: true,
    ens: false,
    Demo: {
      id: 0,
      stake: 1000,
      proposer: '0xEC3215C0ba03fA75c8291Ce92ace346589483E26',
      proposed: '0xcC4cECba564fF334a8d5635D0a45e40da3940E29',
      ProposalStatus: 4,
      marriageContract: '0xEdd3F2251C7B1875BDE327fbf10a8dabdcE433E5',
    },
    currentNetwork: '0x5',
    etherscan: 'https://goerli.etherscan.io/',
    currentNetworkName: 'Goerli Ethereum Chain',
    openSea: 'https://testnets.opensea.io/assets/goerli/',
    alchemy: NEXT_PUBLIC_ALCHEMY_API_GOERLI,
    rpcUrl: `https://eth-goerli.g.alchemy.com/v2/${NEXT_PUBLIC_ALCHEMY_API_GOERLI}`,
  },
  '0xaa36a7': {
    ContractAddress: '0x1fE0f0b015F47607b3f4162a5840000A9308386B',
    currency: 'sETH',
    nftContractAddress: '0xb1698e7fDf1AE5612494c66CA28A8bC97CadE3d7',
    forwarderAddress: '0x0fb35C6D73313CDad234B09ffD70E200a9A17ccB',
    nftResolutionAddress: '0x6ab28fEfFE2Df4A7b3C0Da5b202e4A8e47afF269',
    CompoundV3FacetUSDC: ZERO_ADDR_ETH,
    CompoundV2Facet: ZERO_ADDR_ETH,
    UniSwapV3Facet: ZERO_ADDR_ETH,
    UniSwapV2Facet: ZERO_ADDR_ETH,
    SushiSwapFacet: ZERO_ADDR_ETH,
    QuickSwapV2Facet: ZERO_ADDR_ETH,
    AaveV2Facet: ZERO_ADDR_ETH,
    AaveV3Facet: ZERO_ADDR_ETH,
    YearnFacet: ZERO_ADDR_ETH,
    CurveFacet: ZERO_ADDR_ETH,
    MakerFacet: ZERO_ADDR_ETH,
    MakerInit2: ZERO_ADDR_ETH,
    ParaSwapFacet: ZERO_ADDR_ETH,
    OneInchV5Facet: ZERO_ADDR_ETH,
    LidoFacet: ZERO_ADDR_ETH,
    StargateFacet: ZERO_ADDR_ETH,
    familyDao: '0xE1ec836Cf527DB4f0B9B929A4474435067e545e2',
    WethAddress: '0xf531B8F309Be94191af87605CfBf600D71C2cFe0',
    usdcAddress: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    cETHAddress: '0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5',
    cUSDCAddress: '0x73506770799Eb04befb5AaE4734e58C2C624F493',
    //'0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    compound: true,
    ens: false,
    Demo: {
      id: 0,
      stake: 1000,
      proposer: '0xEC3215C0ba03fA75c8291Ce92ace346589483E26',
      proposed: '0xcC4cECba564fF334a8d5635D0a45e40da3940E29',
      ProposalStatus: 4,
      marriageContract: '0xEdd3F2251C7B1875BDE327fbf10a8dabdcE433E5',
    },
    currentNetwork: '0xaa36a7',
    etherscan: 'https://sepolia.etherscan.io/',
    currentNetworkName: 'Sepolia Ethereum Chain',
    openSea: 'https://testnets.opensea.io/assets/sepolia/',
    alchemy: NEXT_PUBLIC_ALCHEMY_API_SEPOLIA,
    rpcUrl: `https://eth-sepolia.g.alchemy.com/v2/${NEXT_PUBLIC_ALCHEMY_API_SEPOLIA}`,
  },
}
//
