export const INFURA_ID: string | undefined = process.env.NEXT_PUBLIC_INFURA_ID
export const INFURA_POYGON: string | undefined = process.env.NEXT_PUBLIC_INFURA_POYGON
export const API_URL = process.env.NEXT_PUBLIC_APP
export const OPENSEA_API: string | undefined = process.env.NEXT_PUBLIC_OPENSEA_API
export const AMPLITUDE_API: string | undefined = process.env.NEXT_PUBLIC_AMPLITUDE_API
export const NEXT_PUBLIC_ALCHEMY_API_GOERLI: string | undefined = process.env.NEXT_PUBLIC_ALCHEMY_API_GOERLI
export const NEXT_PUBLIC_ALCHEMY_API_SEPOLIA: string | undefined = process.env.NEXT_PUBLIC_ALCHEMY_API_SEPOLIA
export const NEXT_PUBLIC_ALCHEMY_API_MAINNET: string | undefined = process.env.NEXT_PUBLIC_ALCHEMY_API_MAINNET
export const NEXT_PUBLIC_ALCHEMY_API_POLYGON: string | undefined = process.env.NEXT_PUBLIC_ALCHEMY_API_POLYGON
export const NEXT_PUBLIC_LIVECOIN_API: string | undefined = process.env.NEXT_PUBLIC_LIVECOIN_API

export const NEXT_PUBLIC_WEB3AUTH_CLIENT_ID: string | undefined = process.env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID

export const PROVIDERS = {
  '0x89': {
    url: `https://polygon-mainnet.infura.io/v3/${INFURA_POYGON}`,
    chainNumber: 137,
  },
  '0x1': {
    url: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    chainNumber: 1,
  },
  '0x5': {
    url: `https://goerli.infura.io/v3/${INFURA_ID}`,
    chainNumber: 5,
  },
}

export const ZERO_ADDR_ETH = '0x0000000000000000000000000000000000000000'
export const appVersion = '1.0.0'

// Links
export const DISCORD_LINK = 'https://discord.gg/KGQKRVn9'
export const TWITTER_LINK = 'https://twitter.com/_cryptomarry'
export const GITHUB_LINK = 'https://github.com/walletpool/CryptoMarry_Contracts'
export const USER_GUIDE_LINK = 'https://docs.cryptomarry.me/'
export const PRICING_LINK = 'https://docs.cryptomarry.me/pricing'
export const DEPLOYED_CONTRACTS_LINK = 'https://docs.cryptomarry.me/platform-overview'
export const V1_LINK = 'https://v1.cryptomarry.me/'
export const SENDPULSE_ADDRESS_BOOK = 165688
