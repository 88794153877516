import { createSlice } from '@reduxjs/toolkit'
import { MAIN_CONTRACT_STATE, MainContractInterface } from '@/store/features/mainContract/state'
import { CONTRACTS } from '@/configs/contracts'

export interface MainContractState {
  value: number
  boo: boolean
}

const initialState: MainContractInterface = MAIN_CONTRACT_STATE

export const mainContractSlice = createSlice({
  name: 'mainContract',
  initialState,
  reducers: {
    setMarriageContract: (state, action) => {
      state.marriageContract = action.payload
    },
    setMarriageContractEvents: (state, action) => {
      state.marriageContractEvents = action.payload
    },
    loadParams: (state, action) => {
      state.ContractAddress = CONTRACTS[action.payload].ContractAddress
      state.currency = CONTRACTS[action.payload].currency
      state.forwarderAddress = CONTRACTS[action.payload].forwarderAddress
      state.etherscan = CONTRACTS[action.payload].etherscan
      state.nftContractAddress = CONTRACTS[action.payload].nftContractAddress
      state.currentNetwork = CONTRACTS[action.payload].currentNetwork
      state.currentNetworkName = CONTRACTS[action.payload].currentNetworkName
    },
    setContractAddress: (state, action) => {
      state.ContractAddress = action.payload
    },
    setExchangeRate: (state, action) => {
      state.exchangeRate = action.payload
    },
    setSaleCap: (state, action) => {
      state.saleCap = action.payload
    },
    setNFTPrice: (state, action) => {
      state.nftPrice = action.payload
    },
    setClaimertimer: (state, action) => {
      state.claimtimer = action.payload
    },
    setPromoAmount: (state, action) => {
      state.promoAmount = action.payload
    },
    setClaimPolicyDays: (state, action) => {
      state.claimPolicyDays = action.payload
    },
    mainContractReset: () => initialState,
  },
})
// Action creators are generated for each case reducer function
export const {
  setMarriageContract,
  setMarriageContractEvents,
  loadParams,
  setContractAddress,
  setExchangeRate,
  setSaleCap,
  setNFTPrice,
  setClaimertimer,
  setPromoAmount,
  setClaimPolicyDays,
  mainContractReset,
} = mainContractSlice.actions

export default mainContractSlice.reducer
